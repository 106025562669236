import React, { createContext, useContext, useCallback, useMemo, useState } from "react";
import { ColumnInstance } from "react-table";
import { usePopper } from "react-popper";

import { styled, shadows } from "@puzzle/theme";

import { CellContent, EnhancedLedgerReportLine, HighlightRule } from "./types";
import { useDelta } from "./Filters/DeltaProvider";
import useHighlightRules from "./useHighlightRules";
import { BuildReportResult } from "components/dashboard/utils/fetchLedgerReport/types";

type ActiveCell = {
  element?: HTMLElement;
  ruleIndex: number;
};

type HighlightContextType = {
  highlightState?: ActiveCell;
  setHighlightState: React.Dispatch<React.SetStateAction<ActiveCell | undefined>>;
  rules: HighlightRule[];
  optionsForCell: (
    node: EnhancedLedgerReportLine,
    column: ColumnInstance<EnhancedLedgerReportLine>
  ) => Omit<CellContent, "value"> | null | undefined;
};

const HighlightContext = createContext<HighlightContextType | undefined>(undefined);

export const vendorRuleIndex = 3;
const minRuleIndex = 0;

const Tooltip = styled("div", {
  width: "250px",
  padding: "$1",
  backgroundColor: "$black",
  color: "$gray50",
  whiteSpace: "pre-line",
  fontSize: "$bodyXS",
  boxShadow: shadows.black04ToBlack08BottomBlurMediumComposed,
  borderRadius: "$1",
});

export const HighlightProvider = ({
  children,
  report,
}: React.PropsWithChildren<{ report: BuildReportResult }>) => {
  const { deltaOptions } = useDelta();
  const rules = useHighlightRules({ deltaOptions, lastTimePeriod: report.lastTimePeriod });

  const [highlightState, setHighlightState] = useState<ActiveCell | undefined>();
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  // This eventually should use radix.
  // Maybe we can render Tooltip inline again since Radix only renders when it's open?
  // I originally split these contexts up because reakit always rendered several tooltips or popvers in the background.
  const popper = usePopper(highlightState?.element, popperElement, {
    placement: "right",
    modifiers: [
      { name: "flip", enabled: true },
      { name: "offset", options: { offset: [0, 10] } },
    ],
  });

  const optionsForCell = useCallback<HighlightContextType["optionsForCell"]>(
    (node, column) => {
      const matchedQuestion = deltaOptions.enabled
        ? rules.findIndex((question) => {
            if (question.highlight) {
              return question.highlight(node, column.id);
            }
          })
        : -1;

      const vendorHighlight = matchedQuestion === vendorRuleIndex;
      const highlight = matchedQuestion >= minRuleIndex && matchedQuestion < vendorRuleIndex;

      return {
        highlight,
        vendorHighlight,
        active: matchedQuestion === highlightState?.ruleIndex,
        metadata: {
          ruleIndex: highlight || vendorHighlight ? matchedQuestion : undefined,
        },
        timePeriodKey: column.id,
      };
    },
    [highlightState?.ruleIndex, deltaOptions.enabled, rules]
  );

  const value = useMemo(() => {
    return {
      highlightState,
      setHighlightState,
      rules,
      optionsForCell,
    };
  }, [highlightState, optionsForCell, rules]);

  const tooltip = useMemo(() => {
    if (!highlightState?.element) {
      return;
    }

    const { styles, attributes } = popper;
    const rule = rules[highlightState.ruleIndex];

    return (
      <Tooltip ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        {rule.description}
      </Tooltip>
    );
  }, [highlightState, popper, rules]);

  return (
    <HighlightContext.Provider value={value}>
      {children}

      {tooltip}
    </HighlightContext.Provider>
  );
};

export const useHighlight = (): HighlightContextType => {
  const context = useContext(HighlightContext);
  if (context === undefined) {
    throw new Error("useHighlight must be used within HighlightProvider");
  }
  return context;
};
