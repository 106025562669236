import React from "react";
import { styled } from "@puzzle/theme";
import { LightBackgroundLoadingBar } from "components/common/LoadingBar";

const Shimmer = styled(LightBackgroundLoadingBar, {
  height: "100%",
  width: "100%",
  defaultVariants: {
    gridColumn: "neg1",
  },
  variants: {
    gridColumn: {
      neg1: {
        gridColumn: "1 / -1",
      },
      neg3: {
        gridColumn: "3 / -1",
      },
      neg6: {
        gridColumn: "6 / -1",
      },
      thirds: {
        gridColumn: "1 / 3",
      },
    },
  },
});

const Wrapper = styled("div", {
  position: "relative",
  display: "grid",
  padding: "$3",
  gridTemplateColumns: "repeat(6, 1fr)",
  gridTemplateRows: "15px 32px repeat(40, 15px) 15px",
  gridGap: "17px",
  height: "1000px",

  "&::before": {
    content: "",
  },
});

const LoadingReport = () => {
  return (
    <Wrapper>
      <Shimmer gridColumn="thirds" />
      <Shimmer gridColumn="neg6" />
      <Shimmer gridColumn="neg3" />
      <Shimmer />
      <div />
      <Shimmer />
      <div />
      <Shimmer />
      <Shimmer />
      <div />
      <Shimmer />
      <Shimmer />
      <Shimmer />
      <Shimmer />
      <Shimmer />
      <Shimmer />
      <Shimmer />
      <Shimmer />
      <div />
      <Shimmer />
      <Shimmer />
      <Shimmer />
      <Shimmer />
      <Shimmer />
      <Shimmer />
      <div />
      <Shimmer />
      <Shimmer />
      <Shimmer />
      <Shimmer />
      <Shimmer />
      <Shimmer />
    </Wrapper>
  );
};

export default LoadingReport;
