import * as Types from '../../../graphql/types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReportBreakoutLineFragment = { __typename?: 'ReportBreakoutLine', title?: string | null, balance?: string | null, pathToken?: string | null, tags: Array<string>, relatedObjectId?: string | null, lineType?: Types.LedgerReportLineType | null, detail?: { __typename?: 'TransactionDetail', id: string, confirmedState?: Types.DetailConfirmedState | null } | null, transaction?: { __typename?: 'Transaction', integrationType: Types.IntegrationType } | null, metadata: { __typename?: 'LedgerReportEventMetadata', eventType?: string | null, externalAccountId?: string | null, externalId?: string | null, effectiveAt: CalendarDateTimeString } };

export type GetLedgerReportBreakoutByPeriodQueryVariables = Types.Exact<{
  input: Types.LedgerReportBreakoutByPeriodInput;
}>;


export type GetLedgerReportBreakoutByPeriodQuery = { __typename?: 'Query', ledgerReportBreakoutByPeriod: { __typename?: 'LedgerReportBreakoutByPeriod', periods: Array<string>, hasMore: boolean, highWatermarkToken?: string | null, key: string, nextPageStartingAfter?: string | null, lines: Array<{ __typename?: 'ReportBreakoutLine', title?: string | null, balance?: string | null, pathToken?: string | null, tags: Array<string>, relatedObjectId?: string | null, lineType?: Types.LedgerReportLineType | null, detail?: { __typename?: 'TransactionDetail', id: string, confirmedState?: Types.DetailConfirmedState | null } | null, transaction?: { __typename?: 'Transaction', integrationType: Types.IntegrationType } | null, metadata: { __typename?: 'LedgerReportEventMetadata', eventType?: string | null, externalAccountId?: string | null, externalId?: string | null, effectiveAt: CalendarDateTimeString } }> } };

export const ReportBreakoutLineFragmentDoc = gql`
    fragment reportBreakoutLine on ReportBreakoutLine {
  title
  balance
  pathToken
  tags
  relatedObjectId
  lineType
  detail {
    id
    confirmedState
  }
  transaction {
    integrationType
  }
  metadata {
    eventType
    externalAccountId
    externalId
    effectiveAt
  }
}
    `;
export const GetLedgerReportBreakoutByPeriodDocument = gql`
    query getLedgerReportBreakoutByPeriod($input: LedgerReportBreakoutByPeriodInput!) {
  ledgerReportBreakoutByPeriod(input: $input) {
    periods
    hasMore
    highWatermarkToken
    key
    lines {
      ...reportBreakoutLine
    }
    nextPageStartingAfter
  }
}
    ${ReportBreakoutLineFragmentDoc}`;

/**
 * __useGetLedgerReportBreakoutByPeriodQuery__
 *
 * To run a query within a React component, call `useGetLedgerReportBreakoutByPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLedgerReportBreakoutByPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLedgerReportBreakoutByPeriodQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLedgerReportBreakoutByPeriodQuery(baseOptions: Apollo.QueryHookOptions<GetLedgerReportBreakoutByPeriodQuery, GetLedgerReportBreakoutByPeriodQueryVariables> & ({ variables: GetLedgerReportBreakoutByPeriodQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLedgerReportBreakoutByPeriodQuery, GetLedgerReportBreakoutByPeriodQueryVariables>(GetLedgerReportBreakoutByPeriodDocument, options);
      }
export function useGetLedgerReportBreakoutByPeriodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLedgerReportBreakoutByPeriodQuery, GetLedgerReportBreakoutByPeriodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLedgerReportBreakoutByPeriodQuery, GetLedgerReportBreakoutByPeriodQueryVariables>(GetLedgerReportBreakoutByPeriodDocument, options);
        }
export function useGetLedgerReportBreakoutByPeriodSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetLedgerReportBreakoutByPeriodQuery, GetLedgerReportBreakoutByPeriodQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLedgerReportBreakoutByPeriodQuery, GetLedgerReportBreakoutByPeriodQueryVariables>(GetLedgerReportBreakoutByPeriodDocument, options);
        }
export type GetLedgerReportBreakoutByPeriodQueryHookResult = ReturnType<typeof useGetLedgerReportBreakoutByPeriodQuery>;
export type GetLedgerReportBreakoutByPeriodLazyQueryHookResult = ReturnType<typeof useGetLedgerReportBreakoutByPeriodLazyQuery>;
export type GetLedgerReportBreakoutByPeriodSuspenseQueryHookResult = ReturnType<typeof useGetLedgerReportBreakoutByPeriodSuspenseQuery>;
export type GetLedgerReportBreakoutByPeriodQueryResult = Apollo.QueryResult<GetLedgerReportBreakoutByPeriodQuery, GetLedgerReportBreakoutByPeriodQueryVariables>;