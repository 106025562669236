import React from "react";
import {
  DynamicReportType,
  LedgerAccountingPeriodInterval,
  LedgerReportColumnBy,
  IntervalMetadataFragment,
} from "graphql/types";
import TimePeriodHeader from "./TimePeriodHeader";
import { toCalendarMonthString, today } from "@puzzle/utils";
import SegmentHeader from "./SegmentsHeader";
import { DeltaOptions } from "../types";

type ReportColHeaderProps = {
  columnKey: string;
  i: number;
  columnBy: LedgerReportColumnBy;
  reportType: DynamicReportType;
  timePeriods?: IntervalMetadataFragment[];
  deltaOptions: DeltaOptions;
  timeZone: string;
  isInProgress: boolean;
};
export const ReportColHeader = ({
  columnKey,
  i,
  columnBy,
  reportType,
  timePeriods,
  deltaOptions,
  timeZone,
  isInProgress,
}: ReportColHeaderProps) => {
  const enabled = !(deltaOptions.enabled && columnKey.startsWith("Change"));

  if (columnBy === LedgerReportColumnBy.Interval) {
    return (
      <TimePeriodHeader
        reportType={reportType}
        isInProgress={
          (timePeriods?.[i]?.interval &&
          timePeriods[i].interval.__typename === "LedgerAccountingPeriodInterval"
            ? // why didn't the type guard work here? usually checking the __typename is sufficient
              (timePeriods[i].interval as LedgerAccountingPeriodInterval).periods.includes(
                toCalendarMonthString(today(timeZone))
              )
            : false) && isInProgress
        }
        metrics={enabled ? timePeriods?.[i] : undefined}
        label={columnKey}
      />
    );
  } else {
    return <SegmentHeader label={columnKey} />;
  }
};
