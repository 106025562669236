/* eslint-disable react/display-name */
import React, { useMemo } from "react";
import { CellProps, UseTableInstanceProps, Row, HeaderGroup } from "react-table";
import { styled } from "@puzzle/theme";

import { DeltaColumns, EnhancedLedgerReportLine } from "../types";
import { BreakoutCell } from "./BreakoutCell";

import { CaretRight } from "@puzzle/icons";

import { balanceForColumn } from "../util";
import { useHighlight, vendorRuleIndex } from "../HighlightProvider";
import { useDelta } from "../Filters/DeltaProvider";
import Big from "big.js";
import { BreakoutCellData } from "../BreakoutProvider";

export type CellValueProps = CellProps<EnhancedLedgerReportLine, string>;
export type FooterValueProps = UseTableInstanceProps<EnhancedLedgerReportLine> & {
  row: Row<EnhancedLedgerReportLine>;
  column: HeaderGroup<EnhancedLedgerReportLine>;
};

const TitleRoot = styled("div", {
  padding: "$1",
  fontSize: "12px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  pointerEvents: "none",

  "@media print": {
    fontSize: "$bodyL",
    fontWeight: "$normal",
    color: "$gray300",
  },

  defaultVariants: {
    root: false,
    footer: false,
    vendor: false,
    highlight: false,
  },

  variants: {
    root: {
      true: {
        fontWeight: "$heavy",
        textTransform: "uppercase",
        color: "$gray300",
        "@media print": {
          color: "$black",
          fontWeight: "$bold",
          paddingTop: "$3",
        },
      },
      false: {},
    },

    footer: {
      true: {
        paddingTop: "$1",
        paddingRight: "$1",
        paddingBottom: "$1",
        textTransform: "none",
        color: "$gray100",
        borderTop: "1px solid",
        borderColor: "$gray700",

        "@media print": {
          color: "$gray700",
          fontWeight: "$bold",
        },
      },
      false: {},
    },
    vendor: {
      true: {
        borderRadius: "4px",
        backgroundColor: "$yellow300",
        color: "$gray700",
        height: "26px",
        display: "flex",
        alignItems: "center",
      },
      false: {},
    },
    highlight: {
      true: {
        borderRadius: "4px",
        backgroundColor: "#5F5C40",
        color: "$gray200",
        height: "26px",
        display: "flex",
        alignItems: "center",
      },
      false: {},
    },
  },
});

const Truncate = styled("div", {
  overflow: "hidden",
  textOverflow: "ellipsis",
});

function Title({
  children,
  depth,
  footer,
  vendor,
  highlight,
}: React.PropsWithChildren<{
  depth: number;
  footer?: boolean;
  vendor?: boolean;
  highlight?: boolean;
}>) {
  return (
    <TitleRoot
      root={depth === 0}
      css={{ marginLeft: `$${depth * 2}` }}
      footer={footer}
      vendor={vendor}
      highlight={highlight}
    >
      <Truncate>{children}</Truncate>
    </TitleRoot>
  );
}

export function TitleFooter({ row }: { row?: Row<EnhancedLedgerReportLine> }) {
  if (!row) {
    return null;
  }

  const { prefix, modifiers, type } = row.original.formatting.footer;

  return (
    <Title footer depth={row.depth + 1}>
      {`${prefix || ""} ${row.original.title}`.trim()}
    </Title>
  );
}

export function TitleCell({
  row,
  value,
}: CellProps<EnhancedLedgerReportLine, Pick<EnhancedLedgerReportLine, "title">>) {
  const { title } = value;
  const iconRotate = row.isExpanded ? 90 : 0;
  const { prefix } = row.original.formatting.header;
  const { highlightState } = useHighlight();
  const { deltaOptions } = useDelta();

  const percentDiffForNode = balanceForColumn(
    DeltaColumns.PercentDiff,
    row.original.balanceByColumn
  );
  const dollarDiffForNode = balanceForColumn(DeltaColumns.DollarDiff, row.original.balanceByColumn);
  const vendorHighlight = useMemo(
    () =>
      row.original.metadata?.counterpartyType === "vendor" &&
      Big(percentDiffForNode.value).eq(0) &&
      !Big(dollarDiffForNode.value).eq(0) &&
      deltaOptions.enabled,
    [
      row.original.metadata?.counterpartyType,
      percentDiffForNode.value,
      dollarDiffForNode.value,
      deltaOptions.enabled,
    ]
  );

  return useMemo(() => {
    return (
      <Title
        depth={row.depth}
        vendor={highlightState?.ruleIndex === vendorRuleIndex && vendorHighlight}
        highlight={highlightState?.ruleIndex !== vendorRuleIndex && vendorHighlight}
        {...row.getToggleRowExpandedProps()}
      >
        <span
          style={{
            position: "absolute",
            transform: "translate(-150%, -50%)",
            lineHeight: 0,
            top: "50%",
            display: "inline-block",
          }}
        >
          {row.subRows.length > 0 && (
            <CaretRight
              rotate={iconRotate}
              css={{
                "@media print": {
                  display: "none",
                },
              }}
            />
          )}
        </span>

        {`${prefix || ""} ${title}`.trim()}
      </Title>
    );
  }, [row, iconRotate, prefix, title, highlightState, vendorHighlight]);
}

const FooterCell = styled("div", {
  borderTop: "1px solid",
  borderColor: "$gray700",
  color: "$gray100",

  "@media print": {
    fontSize: "$bodyL",
  },
});

export function BodyValueCell(
  props: CellValueProps,
  cellContext: Pick<BreakoutCellData, "categoriesByPermaKey" | "stickyOptions">
) {
  const { row } = props;

  if (row.canExpand && row.isExpanded) {
    return null;
  }

  return <BreakoutCell {...props} {...cellContext} />;
}

export function FooterValueCell(
  props: FooterValueProps,
  cellContext: Pick<BreakoutCellData, "categoriesByPermaKey" | "stickyOptions">
) {
  const { column, row } = props;

  return useMemo(() => {
    if (!row) {
      return null;
    }

    const cell = row.cells.find((c) => c.column.id === column.id);
    const value = cell?.value || "$0";

    return (
      <FooterCell>
        <BreakoutCell {...props} {...cellContext} value={value} variant="footer" />
      </FooterCell>
    );
  }, [column.id, props, row]);
}
