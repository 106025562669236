/* eslint-disable react/display-name */
import React from "react";
import { styled } from "@puzzle/theme";
import { ToggleGroup } from "components/common/ToggleGroup/ToggleGroup";
import { useStickyReportContext } from "components/reports/StickyReportContext";
import { DetailLevel } from "./types";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics/featureFlags";
import { DATA_TEST_IDS } from "./constants";

const defaultCss = {
  width: "432px",
  marginBottom: "$3",
};

const darkBackLightFrontCss = {
  marginTop: "-$3h",
};

const ReportLevelContainer = styled("div", {
  paddingLeft: "$3",
  paddingTop: "$3",
  "@media print": {
    [`${ToggleGroup.Root}, ${ToggleGroup.Item}`]: {
      display: "none",
    },
  },
});

export const ReportLevel = React.memo(() => {
  const levels: `${DetailLevel}`[] = ["Compact", "Summary", "Detailed"];
  const { stickyOptions, setStickyOptions } = useStickyReportContext();

  return (
    <ReportLevelContainer>
      <ToggleGroup.Root
        type="single"
        darkBackLightFront={isPosthogFeatureFlagEnabled(FeatureFlag.DashboardFsReportsWithStatus)}
        data-testid={DATA_TEST_IDS.REPORT_DETAIL_LEVEL}
        onValueChange={(value) => value && setStickyOptions({ detailLevel: value as DetailLevel })}
        value={stickyOptions.detailLevel}
        css={
          isPosthogFeatureFlagEnabled(FeatureFlag.DashboardFsReportsWithStatus)
            ? darkBackLightFrontCss
            : defaultCss
        }
      >
        {levels.map((label) => {
          const value = DetailLevel[label].toString();
          return (
            <ToggleGroup.Item key={value} value={value} title={label}>
              {label}
            </ToggleGroup.Item>
          );
        })}
      </ToggleGroup.Root>
    </ReportLevelContainer>
  );
});
