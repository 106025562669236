export const DATA_TEST_IDS = {
  SYNC_STATUS_TEXT: "sync-status-text",
  SYNC_STATUS_REFRESH_BUTTON: "sync-status-refresh-button",
  REPORT_CONTAINER: "report-container",
  REPORT_TABS: "ReportTabs",
  REPORT_DETAIL_LEVEL: "ReportDetailLevel",
  SPOTLIGHT_BUTTON: "SpotlightButton",
  SPOTLIGHT_SIGNIFICANT_VALUES: "SpotlightSignificantValues",
  REPORT_VIEW_OPTION: "ReportViewOption",
  REPORT_BASIS_OPTION: "ReportBasisOption",
};
