import React, { useMemo } from "react";
import { Button, Text } from "@puzzle/ui";
import { styled } from "@puzzle/theme";
import { ChevronLeft, ChevronRight } from "@puzzle/icons";
import { pluralize } from "@puzzle/utils";

const Root = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  color: "$gray500",
  gap: "$2",
});

const ButtonGroup = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "$1h",
});

const Paginator = ({
  totalItems,
  page,
  totalPages,
  onPageChange,
  itemsPerPage,
  hasNextPage,
}: {
  totalItems?: number;
  totalPages?: number;
  itemsPerPage?: number;
  page: number;
  onPageChange: (page: number) => void;
  hasNextPage?: boolean;
}) => {
  const minPage = 0;
  const maxPage = totalItems && itemsPerPage && Math.ceil(totalItems / itemsPerPage) - 1;

  const label = useMemo(() => {
    if (typeof itemsPerPage === "number" && typeof totalItems === "number") {
      const min = page * itemsPerPage + 1;
      const max = Math.min((page + 1) * itemsPerPage, totalItems);
      return (min === max ? min : `${min} - ${max}`) + ` of ${pluralize(totalItems, "result")}`;
    } else if (typeof totalPages === "number" && typeof page === "number") {
      if (page + 1 !== totalPages) {
        return `${page + 1} of ${pluralize(totalPages, "page")}`;
      }
    }
  }, [totalItems, page, totalPages, itemsPerPage]);

  return (
    <Root>
      <Text size="bodyXS" css={{ whiteSpace: "nowrap" }}>
        {label}
      </Text>

      <ButtonGroup>
        <Button
          variant="secondary"
          size="small"
          icon
          disabled={minPage ? page === minPage : page === 0}
          onClick={() => onPageChange(page - 1)}
        >
          <ChevronLeft color="currentColor" />
        </Button>

        <Button
          variant="secondary"
          size="small"
          icon
          disabled={maxPage ? page >= maxPage : !hasNextPage}
          onClick={() => onPageChange(page + 1)}
        >
          <ChevronRight color="currentColor" />
        </Button>
      </ButtonGroup>
    </Root>
  );
};

export default Paginator;
