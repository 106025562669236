import React, { useMemo, useCallback } from "react";
import NextLink from "next/link";

import { DynamicReportType } from "graphql/types";
import { BreakoutCellData, useBreakout } from "../BreakoutProvider";
import { useHighlight } from "../HighlightProvider";
import { getLinkForAccrualRevenueTotal } from "../util";
import InteractiveCell from "./InteractiveCell";
import { useDelta } from "../Filters/DeltaProvider";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics/featureFlags";
import Analytics from "lib/analytics/analytics";

export const BreakoutCell = (
  props: BreakoutCellData & {
    value: string;
    variant?: "footer";
  }
) => {
  const { categoriesByPermaKey, stickyOptions, row, rowsById, column, value, variant } = props;
  const isDiff = column.id.startsWith("Change");

  const node = row?.original;
  const { deltaOptions } = useDelta();
  const { setHighlightState, optionsForCell } = useHighlight();
  const { view, activeTab, filter } = stickyOptions;
  const { reportType, breakoutData, setBreakoutData, renderBreakout, link } = useBreakout({
    categoriesByPermaKey,
    stickyOptions,
    row,
    column,
    rowsById,
  });

  const timePeriod = useMemo(() => {
    return node?.balanceByColumn.find((p) => p.columnKey === column.id);
  }, [column.id, node?.balanceByColumn]);

  const hasTimePeriod = Boolean(
    timePeriod?.dateRange.fromInclusive && timePeriod?.dateRange.toInclusive
  );
  const hasChildren = Boolean(row?.canExpand && variant !== "footer");
  const hasBreakout = Boolean(!hasChildren && timePeriod?.pathToken && hasTimePeriod);
  const isAccrualRevenueSource = node.lineType === "accrual_revenue_source";

  const options = useMemo(() => {
    if (!node) {
      return;
    }

    return optionsForCell(node, column);
  }, [column, node, optionsForCell]);
  const active = options?.active || (row === breakoutData?.row && column === breakoutData?.column);

  const handleHoverStart = useCallback(
    (e: React.MouseEvent<HTMLButtonElement> | React.FocusEvent<HTMLButtonElement>) => {
      const ruleIndex = options?.metadata?.ruleIndex;
      if (typeof ruleIndex !== "number") {
        return;
      }

      setHighlightState({
        ruleIndex,
        element: e.target as HTMLElement,
      });

      Analytics.dashboardReportHighlightCellHovered({
        ruleIndex,
        depth: row.depth,
        rowName: node?.title,
        reportType,
        columnName: column.id,
      });
    },
    [column.id, node?.title, options?.metadata?.ruleIndex, reportType, row.depth, setHighlightState]
  );

  const handleHoverEnd = useCallback(() => {
    setHighlightState(undefined);
  }, [setHighlightState]);

  return useMemo(() => {
    const hasFilter = filter?.segments?.segmentIds?.length && filter.segments.segmentIds.length > 0;

    const eventBreakoutEnabled = isPosthogFeatureFlagEnabled(FeatureFlag.EnableEventBreakout)
      ? true
      : !hasFilter || activeTab !== DynamicReportType.ProfitAndLoss;

    const onClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
      if (!eventBreakoutEnabled) return;
      if (row && hasBreakout) {
        Analytics.dashboardReportCellClicked({
          depth: row.depth,
          rowName: node?.title,
          reportType,
          columnName: column.id,
          highlightModeEnabled: deltaOptions.enabled,
        });
        setBreakoutData({ categoriesByPermaKey, stickyOptions, row, column, rowsById });
      }
    };

    const cellProps = {
      variant,
      hasChildren,
      children: value,
      open: eventBreakoutEnabled ? active : false,
      canOpen: eventBreakoutEnabled ? hasBreakout && !options?.vendorHighlight : false,
      highlight: options?.highlight ?? false,
      vendorHighlight: options?.vendorHighlight ?? false,
      onMouseEnter: handleHoverStart,
      onMouseLeave: handleHoverEnd,
      onFocus: handleHoverStart,
      onBlur: handleHoverEnd,
    };

    let content = <InteractiveCell onClick={onClick} root={row.depth === 0} {...cellProps} />;

    if (isAccrualRevenueSource && link && hasTimePeriod) {
      content = (
        <NextLink href={link} passHref legacyBehavior>
          <InteractiveCell
            as="a"
            external
            target="_blank"
            rel="noopener noreferrer"
            {...cellProps}
          />
        </NextLink>
      );
    }

    if (!isDiff && link && variant === "footer") {
      const isAccrualRevenueTotalCell =
        node.title.includes("Revenue") &&
        reportType === DynamicReportType.ProfitAndLoss &&
        view === "accrual";

      const _link = isAccrualRevenueTotalCell
        ? getLinkForAccrualRevenueTotal({ row, column, categoriesByPermaKey })
        : link;

      content = (
        <NextLink href={_link} passHref legacyBehavior>
          <InteractiveCell
            {...cellProps}
            canOpen
            as="a"
            external
            target="_blank"
            rel="noopener noreferrer"
          />
        </NextLink>
      );
    }

    return <>{renderBreakout(content, props)}</>;
  }, [
    variant,
    hasChildren,
    value,
    active,
    hasBreakout,
    options?.highlight,
    isDiff,
    link,
    renderBreakout,
    props,
    row,
    node?.title,
    reportType,
    column,
    deltaOptions.enabled,
    setBreakoutData,
    rowsById,
    options?.vendorHighlight,
    view,
    isAccrualRevenueSource,
    hasTimePeriod,
    categoriesByPermaKey,
    filter?.segments,
    activeTab,
  ]);
};
