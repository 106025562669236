/* eslint-disable react/display-name */
import React, { ComponentProps } from "react";
import Stitches from "@stitches/react";

import { Button } from "@puzzle/ui";
import { styled } from "@puzzle/theme";

import HoverCard from "components/common/HoverCard";
import StatusMessage from "./StatusMessage";

const Metric = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",

  color: "$gray200",

  width: "100%",
  padding: "$1",
  paddingRight: "$2",
  whiteSpace: "nowrap",
  fontSize: "13px",
  lineHeight: "20px",
  borderBottom: "1px solid $mauve680",

  variants: {
    variant: {
      total: {
        color: "$gray400",
        fontWeight: "$bold",
      },
    },
  },
});

const Header = styled("div");
const Body = styled("div");
const Footer = styled("div", {
  display: "flex",
  flexDirection: "row",
  marginTop: "$2",
});

const Metrics = ({
  children,
  header,
  footer,
  css,
}: React.PropsWithChildren<{
  header?: React.ReactNode;
  footer?: React.ReactNode;
  css?: Stitches.CSS;
}>) => {
  return (
    <Body css={css}>
      {header && <Header>{header}</Header>}
      {children}
      {footer && <Footer>{footer}</Footer>}
    </Body>
  );
};

export const StatusCard = ({
  children,
  label,
  trigger,
  ...props
}: Omit<ComponentProps<typeof HoverCard>, "children"> &
  React.PropsWithChildren<{
    label: string;
  }>) => {
  return (
    <HoverCard
      width={400}
      // NOTE: If there's not enough vertical space, it won't change axes.
      // https://github.com/radix-ui/primitives/issues/973
      side="top"
      align="center"
      arrow
      trigger={<span>{trigger}</span>}
      {...props}
    >
      <HoverCard.Title>{label}</HoverCard.Title>

      <HoverCard.Body>{children}</HoverCard.Body>
    </HoverCard>
  );
};

const StatusCardButton = React.forwardRef<HTMLButtonElement, React.ComponentProps<typeof Button>>(
  (props, ref) => {
    return <Button size="small" variant="primary" {...props} ref={ref} />;
  }
);

StatusCard.Message = StatusMessage;
StatusCard.Metrics = Object.assign(Metrics, { Item: Metric });
StatusCard.Button = StatusCardButton;
