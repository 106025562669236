import omit from "lodash/omit";
import React, { useCallback, useEffect, useMemo } from "react";
import * as Stitches from "@stitches/react";
import { VariantProps } from "@stitches/react";

import { Collapse } from "@puzzle/ui";
import { styled } from "@puzzle/theme";

import {
  Cell,
  Column,
  HeaderGroup,
  Hooks,
  Meta,
  Row,
  TableCellProps,
  TableDispatch,
  TableFooterProps,
  TableHeaderProps,
  TableOptions,
  TableState,
  useExpanded,
  useSortBy,
  useTable,
} from "react-table";

import { useSticky } from "./useSticky";
import Paginator from "./Paginator";
import CopyCell from "./CopyCell";

// Custom action for updating the `expanded` keys.
const SET_EXPANDED_ROWS = "setExpandedRows";

const NonInteractiveCell = styled("td", {
  "*:hover &": {
    background: "inherit !important",
  },

  cursor: "initial",
});

const DataStatusCell = styled(NonInteractiveCell, {
  textAlign: "center",
  color: "$gray400",
});

const StyledTable = styled("table", {
  "&, table": {
    width: "100%",
    tableLayout: "fixed",
    borderCollapse: "collapse",
    overflow: "hidden",
  },

  "& *": {
    // TODO not ideal for dates; perhaps we need a data-type or something
    fontVariantNumeric: "tabular-nums",
  },

  th: {
    color: "$gray400",
    fontSize: "12px",
    lineHeight: "14px",
    textAlign: "left",
    fontWeight: "$bold",
    letterSpacing: "0.5px",
    padding: "$1",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",

    "button, svg": {
      verticalAlign: "middle",
    },
  },

  td: {
    color: "$gray200",
    fontSize: "14px",
    letterSpacing: "0.2px",
    lineHeight: "18px",
  },

  tbody: {
    "tr:not(.subrow):not(.subnode):not(.subnode td tr):hover td": {
      backgroundColor: "#26243B",
    },
  },

  "tr[role=row]": {
    borderBottom: "1px solid",
    borderBottomColor: "$mauve650",
  },

  "tr[data-disabled=true] td": {
    color: "$gray600",
  },

  tfoot: {
    td: {
      textStyle: "$bodyS",
      color: "$gray50",
    },

    "td:empty": {
      padding: "0",
    },

    "td[colspan='0']": {
      display: "none",
    },
  },

  ".spacer-row": {
    display: "none",
  },

  defaultVariants: {
    size: "medium",
    clickableRows: false,
    loading: false,
    bordered: false,
    sticky: false,
  },

  variants: {
    sticky: {
      true: {
        "&, table": {
          overflow: "scroll",

          thead: {
            th: {
              backgroundColor: "$mauve800",
            },
          },

          "tfoot, thead": {
            position: "sticky",
            zIndex: 0,
          },

          "td[data-sticky-td]": {
            backgroundColor: "$mauve800",
            position: "sticky",
          },

          "[data-sticky-last-left-td]": {
            "&::after": {
              borderRight: "1px solid $mauve650",
              content: "",
              position: "absolute",
              height: "100%",
              width: "100%",
              top: 0,
              left: 0,
              zIndex: -1,
            },
          },

          "th[data-sticky-last-left-td]": {
            overflow: "inherit",
          },

          "[data-sticky-first-right-td]": {
            "&::after": {
              borderLeft: "1px solid $mauve650",
              content: "",
              position: "absolute",
              height: "100%",
              width: "100%",
              top: 0,
              left: 0,
              zIndex: -1,
            },
          },
        },
      },
      false: {},
    },

    loading: {
      true: {},
      false: {},
    },

    clickableRows: {
      true: {
        tr: {
          cursor: "pointer",

          "&[aria-disabled=true]": {
            cursor: "initial",
          },
        },

        ".subnode": {
          borderBottom: "1px solid $mauve650",
          cursor: "inherit",
        },
      },
      false: {},
    },

    bordered: {
      true: {
        borderCollapse: "collapse",

        "thead, tbody": {
          th: {
            backgroundColor: "$mauve850",
            borderRight: "1px solid $mauve650",
            borderTop: "1px solid $mauve650",

            "&:first-child": {
              borderLeft: "none",
            },

            "&:last-child": {
              borderRight: "none",
            },
          },

          td: {
            borderRight: "1px solid $mauve650",

            "&:last-child": {
              borderRight: "none",
            },
          },
        },

        [`${DataStatusCell}`]: {
          textStyle: "$headingS",
          // backgroundColor: "#100F1A",
          color: "$gray400",
        },
      },
      false: {},
    },

    size: {
      medium: {
        td: {
          padding: "$1h $1",
        },

        [`${DataStatusCell}`]: {
          padding: "$2 $1",
        },
      },

      small: {
        [`td, ${DataStatusCell}`]: {
          padding: "$1",
        },
      },

      mini: {
        [`td, ${DataStatusCell}`]: {
          padding: "$0h $1",
        },
      },
    },
  },
  compoundVariants: [
    {
      bordered: true,
      sticky: true,
      css: {
        thead: {
          th: {
            backgroundColor: "$mauve850",
          },
        },
      },
    },
  ],
});

const getStyles = (
  props: any,
  {
    style,
    align = "left",
    disableResizing = false,
    verticalAlign,
    width,
  }: {
    style?: Record<string, unknown>;
    disableResizing?: boolean;
    align?: "left" | "right" | "center";
    verticalAlign?: "middle" | "top" | "bottom";
    copyable?: "left" | "right" | "center";
    width?: number | string;
  }
) => [
  props,
  {
    style: {
      ...style,
      verticalAlign: verticalAlign,
      textAlign: align ?? "left",
      width: width || "auto",
    },
  },
];

const getBaseHeaderProps = <T extends Record<string, unknown>>(
  props: Partial<TableHeaderProps>,
  { column }: Meta<T, { column: HeaderGroup<T> }>
) => {
  return getStyles(props, {
    style: column?.style,
    disableResizing: column?.disableResizing,
    align: column?.align,
    verticalAlign: column?.verticalAlign,
    width: column?.width,
    ...column.style,
  });
};

const getBaseFooterProps = <T extends Record<string, unknown>>(
  props: Partial<TableFooterProps>,
  { column }: Meta<T, { column: HeaderGroup<T> }>
) => {
  return getStyles(
    { ...props, colSpan: column?.footerProps?.colSpan },
    {
      style: column?.style,
      disableResizing: column?.disableResizing,
      align: column?.align,
      width: column?.width,
      ...column.style,
    }
  );
};

const getBaseCellProps = <T extends Record<string, unknown>>(
  props: Partial<TableCellProps>,
  { cell }: Meta<T, { cell: Cell<T> }>
) =>
  getStyles(
    {
      props,
      title: cell.value,
    },
    {
      style: cell.column?.style,
      disableResizing: cell.column?.disableResizing,
      align: cell.column?.align,
      width: cell.column?.width,
    }
  );

function useDefaultGetters<T extends Record<string, unknown>>(hooks: Hooks<T>) {
  hooks.getCellProps.push(getBaseCellProps);
  hooks.getHeaderProps.push(getBaseHeaderProps);
  hooks.getFooterProps.push(getBaseFooterProps);
  hooks.stateReducers.push((state, action, previousState, instance) => {
    if (action.type === SET_EXPANDED_ROWS) {
      return {
        ...state,
        expanded: action.value,
      };
    }

    return state;
  });
}

useDefaultGetters.pluginName = "useDefaultGetters";

const defaultPropGetter = () => ({});
/**
 * @deprecated use packages/ui/src/lib/DataTable/DataTable.tsx instead
 */
function BaseDataTable<T extends Record<string, unknown>>({
  className,
  columns,
  data,
  onChange,
  options,
  loading,
  sticky,
  emptyText = "No results found.",
  onRowClick,
  isRowDisabled,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  getHeaderProps = defaultPropGetter,
  getFooterProps = defaultPropGetter,
  getFooterGroupProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  rowTitleKey,
  hideFooter = false,
  hideSpacerRows = false,
  dispatchRef,
  skipResetRef,
  body: _body,
  css,
  renderRowSubNode,
  expandSubrowsByDefault,
  ...props
}: VariantProps<typeof StyledTable> & {
  className?: string;
  columns: Column<T>[];
  data: T[];
  onChange?: (state: TableState<T>) => void;
  // Memoize individual options as needed.
  options?: Partial<TableOptions<T>>;
  loading?: boolean;
  sticky?: boolean;
  emptyText?: string;
  onRowClick?: (r: Row<T>, e: React.MouseEvent) => void;
  renderRowSubNode?: (r: Row<T>) => React.ReactNode;
  isRowDisabled?: (r: Row<T>) => boolean;
  getHeaderProps?: (column: Column<T>) => Record<string, unknown>;
  getColumnProps?: (column: Column<T>) => Record<string, unknown>;
  getRowProps?: (row: Row<T>) => Record<string, unknown>;
  getCellProps?: (cell: Cell<T>) => Record<string, unknown>;
  getFooterProps?: (column: Column<T>) => Record<string, unknown>;
  getFooterGroupProps?: (column: Column<T>) => Record<string, unknown>;
  rowTitleKey?: keyof T;
  hideFooter?: boolean;
  hideSpacerRows?: boolean;
  dispatchRef?: React.MutableRefObject<TableDispatch | undefined>;
  skipResetRef?: React.MutableRefObject<boolean>;
  body?: React.ReactNode;
  css?: Stitches.CSS;
  expandSubrowsByDefault?: boolean;
}) {
  const extendedColumns = useMemo(() => {
    return columns.map((column) => ({
      defaultCanSort: false,
      Footer: () => null,
      ...column,
    }));
  }, [columns]);

  const skipReset = skipResetRef?.current ?? false;

  useEffect(() => {
    if (skipResetRef) {
      skipResetRef.current = false;
    }
  }, [skipResetRef]);

  const {
    getTableProps,
    getTableBodyProps,
    footerGroups,
    headerGroups,
    rows,
    prepareRow,
    state,
    dispatch,
    setHiddenColumns,
    rowsById,
    toggleAllRowsExpanded,
  } = useTable(
    {
      ...options,
      autoResetPage: skipReset,
      autoResetGroupBy: skipReset,
      autoResetSelectedRows: skipReset,
      autoResetSortBy: skipReset,
      autoResetFilters: skipReset,
      autoResetRowState: skipReset,
      autoResetExpanded: skipReset,
      columns: extendedColumns,
      expandSubRows: false,
      data,
    },
    useSortBy,
    useDefaultGetters,
    useExpanded,
    useSticky
  );

  useEffect(() => {
    if (!dispatchRef) {
      return;
    }

    dispatchRef.current = dispatch;
  }, [dispatchRef, dispatch]);

  useEffect(() => {
    const value = options?.initialState?.expanded;
    if (value) {
      dispatch({
        type: SET_EXPANDED_ROWS,
        value,
      });
    }
  }, [dispatch, options?.initialState?.expanded]);

  useEffect(() => {
    if (onChange) {
      onChange(state);
    }
  }, [onChange, state]);

  useEffect(() => {
    if (expandSubrowsByDefault) {
      toggleAllRowsExpanded();
    }
  }, [expandSubrowsByDefault, toggleAllRowsExpanded]);

  const renderRow = useCallback(
    function renderRow(row: Row<T>, i: number) {
      prepareRow(row);
      const rowProps = row.getRowProps(getRowProps(row));
      const disabled = isRowDisabled ? isRowDisabled(row) : false;

      return (
        <React.Fragment key={i}>
          <tr
            aria-disabled={disabled}
            onClick={(e) => onRowClick && !disabled && onRowClick(row, e)}
            {...rowProps}
            key={rowProps.key}
          >
            {row.cells.map(function renderCell(cell, j) {
              const {
                // @ts-expect-error ts-ignore
                props,
                // @ts-expect-error ts-ignore
                title,
                ...cellProps
              } = cell.getCellProps(getCellProps(cell));

              return (
                <td {...cellProps} key={`${cellProps.key}-${j}`}>
                  {cell.render("Cell")}
                </td>
              );
            })}
          </tr>

          {row.subRows.length > 0 && (
            <tr className="subrow">
              <td colSpan={row.cells.length} style={{ padding: 0 }}>
                <Collapse open={row.isExpanded}>
                  <StyledTable
                    sticky={sticky}
                    data-is-leaf={row.depth > 0 && row.subRows.every((x) => !x.canExpand)}
                  >
                    <tbody>{row.subRows.map(renderRow)}</tbody>

                    <tfoot>
                      {footerGroups.map(function renderFooterGroup(group, i) {
                        return (
                          <tr {...group.getFooterGroupProps()} key={i}>
                            {group.headers.map(function renderFooterHeader(column, j) {
                              return (
                                <td {...column.getFooterProps(getFooterProps(column))} key={j}>
                                  {column.render("Footer", { row })}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tfoot>
                  </StyledTable>
                </Collapse>
              </td>
            </tr>
          )}

          {renderRowSubNode && (
            <tr className="subnode">
              <td colSpan={row.cells.length} style={{ padding: 0 }}>
                <Collapse open={row.isExpanded}>{renderRowSubNode(row)}</Collapse>
              </td>
            </tr>
          )}

          {!hideSpacerRows && (
            <tr className="spacer-row">
              <td colSpan={row.cells.length}></td>
            </tr>
          )}
        </React.Fragment>
      );
    },
    [
      footerGroups,
      getCellProps,
      getFooterProps,
      getRowProps,
      hideSpacerRows,
      isRowDisabled,
      onRowClick,
      prepareRow,
      renderRowSubNode,
      sticky,
    ]
  );

  // useMemo doesn't't properly update after sorting
  const header = (
    <thead>
      {headerGroups.map((headerGroup) => {
        const headerGroupProps = headerGroup.getHeaderGroupProps();
        return (
          <tr {...headerGroupProps} key={headerGroupProps.key}>
            {headerGroup.headers.map((column) => {
              const canSort = column.defaultCanSort ?? column.canSort;
              const getToggleProps = canSort ? column.getSortByToggleProps : defaultPropGetter;
              const headerProps = column.getHeaderProps([getToggleProps(), getHeaderProps(column)]);

              const title = column.render("Header");

              return (
                <th
                  key={headerProps.key}
                  {...omit(headerProps, "key")}
                  title={typeof title === "string" ? title : undefined}
                >
                  {title} {column.isSorted && (column.isSortedDesc ? "↓" : "↑")}
                </th>
              );
            })}
          </tr>
        );
      })}
    </thead>
  );

  const body = useMemo(
    function body() {
      return (
        <tbody {...getTableBodyProps()}>
          {_body ? (
            <tr role="row">
              <NonInteractiveCell colSpan={columns.length}>{_body}</NonInteractiveCell>
            </tr>
          ) : (
            <>
              {rows.length === 0 ? (
                <tr role="row">
                  <DataStatusCell colSpan={columns.length}>
                    {loading ? "Loading..." : emptyText}
                  </DataStatusCell>
                </tr>
              ) : (
                rows.map(renderRow)
              )}
            </>
          )}
        </tbody>
      );
    },
    [_body, columns.length, emptyText, getTableBodyProps, loading, renderRow, rows]
  );

  const footer = useMemo(
    function footer() {
      return (
        !hideFooter && (
          <tfoot>
            {footerGroups.map((group, i) => (
              <tr {...group.getFooterGroupProps()} key={i}>
                {group.headers.map((column, j) => {
                  return (
                    <td {...column.getFooterProps()} key={j}>
                      {column.render("Footer")}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tfoot>
        )
      );
    },
    [footerGroups, hideFooter]
  );

  return (
    <StyledTable
      {...props}
      // {...getTableProps()}
      loading={loading}
      sticky={sticky}
      clickableRows={!!onRowClick}
      data-testid="DataTable"
      className={className}
      css={css}
    >
      {header}

      {body}

      {footer}
    </StyledTable>
  );
}

BaseDataTable.toString = StyledTable.toString;

const Header = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "$2",
  maxWidth: "100%",
});

const Stats = styled("div", {
  fontWeight: "$bold",
  fontSize: "12px",
  lineHeight: "16px",
  letterSpacing: "0.5px",
  color: "$gray100",
  textAlign: "right",
  whiteSpace: "nowrap",

  "> *": {
    display: "inline-block",
    padding: "0 $1",
    borderRight: "1px solid",
    borderColor: "$gray700",

    "&:last-child": {
      paddingRight: "0",
      borderRight: "none",
    },
  },
});

const Loader = styled("div");

export const DataTable = Object.assign(BaseDataTable, {
  Header,
  Stats,
  Loader,
  Paginator,
  CopyCell,
});
